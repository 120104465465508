import React, { useState, useEffect } from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Cta from "../components/cta";
import Hero from "../components/hero";

import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Button from "../components/button";

function JoinUs({ data }) {
    let d = data.contentfulJoinUs;

    // Fetch latest jobs
    const [getJobs, setJob] = useState([]);

    useEffect(() => {
        fetch(
            `https://simplescraper.io/api/gV3lOinCFiOEWMlAyDKv?apikey=VT2551jK1s8JRNgq1qx7J0qvxWw1vmev&limit=100`
        )
            .then((response) => response.json())
            .then((resultData) => {
                let jobs = resultData.data;
                setJob(jobs);
            });
    }, []);

    return (
        <Layout>
            <SEO title={d.title} description={d.subtitle} />

            <Hero
                props={{
                    padding: `10`,
                    title: d.title,
                    desc: d.subtitle,
                }}
            />

            <section className="bg-gray-50 flex flex-wrap">
                {[0, 1, 2, 3].map((i) => (
                    <img
                        src={`../images/team/${i + 1}.png`}
                        key={i}
                        className="w-1/2 md:w-1/4"
                    />
                ))}
            </section>

            <section
                className="bg-gray-200 dark:bg-gray-800"
                style={{
                    backgroundImage: `linear-gradient(0, rgba(255,255,255, .55) 0%, rgba(255,255,255, .95) 100%), url(https://images.unsplash.com/photo-1531973576160-7125cd663d86?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80)`,
                    backgroundPosition: `50% 50%`,
                    backgroundSize: `cover`,
                }}
            >
                <div className="w-full max-w-5xl mx-auto px-4 py-8 md:px-8 md:py-16">
                    <h2 className="text-center text-2xl md:text-4xl text-gray-700 font-display mb-10">
                        {d.whatWeDo.title}
                    </h2>
                    <div className="flex flex-col md:flex-row">
                        {d.whatWeDo.content.map((item, i) => (
                            <div
                                className="w-full md:w-1/3 mb-6 px-3"
                                key={item.title}
                            >
                                <div className="flex bg-white dark:bg-gray-700 p-6 rounded-lg shadow-sm">
                                    <div className="flex-auto">
                                        <img
                                            src={`../icons/wedo-${i + 1}.svg`}
                                            className="h-12 mb-3"
                                        />
                                        <h1 className="text-primary dark:text-gray-200 text-xl font-medium mb-4 leading-tight font-display">
                                            {item.title}
                                        </h1>
                                        <p>{item.desc}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            <section className="bg-gray-50 dark:bg-gray-800">
                <div className="w-full max-w-5xl mx-auto px-4 py-8 md:px-8 md:py-16">
                    <h2 className="text-center text-2xl md:text-4xl text-gray-700 dark:text-gray-200 font-display mb-10">
                        Open positions
                    </h2>

                    <div className="mx-auto max-w-2xl flex flex-col">
                        {getJobs.map((item, i) => (
                            <div
                                key={i}
                                className="shadow-md px-6 py-4 mb-4 flex flex-row items-center space-x-10 rounded-lg border border-gray-100 dark:border-gray-700 bg-white dark:bg-gray-700"
                            >
                                <div className="flex-auto">
                                    <h3 className="font-sans leading-tight mb-3 text-xl">
                                        {item.title}
                                    </h3>

                                    <div className="flex">
                                        <div className="flex mr-8 items-center">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="w-4 h-4 mr-1 stroke-current text-gray-400 dark:text-gray-200"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                                                />
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                                                />
                                            </svg>
                                            <span className="text-gray-400 text-sm">
                                                Indonesia
                                            </span>
                                        </div>

                                        <div className="flex items-center">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="w-4 h-4 mr-1 stroke-current text-gray-400 dark:text-gray-200"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                                                />
                                            </svg>
                                            <span className="text-gray-400 text-sm">
                                                Fulltime
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex-none flex w-auto">
                                    <Button
                                        text="Apply"
                                        size="md"
                                        color="primary"
                                        url={item.title_link}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            <Cta />
        </Layout>
    );
}

JoinUs.propTypes = {
    data: PropTypes.object,
};

export const query = graphql`
    {
        contentfulJoinUs {
            title
            subtitle
            whatWeDo {
                title
                content {
                    title
                    desc
                }
            }
        }
    }
`;

export default JoinUs;
